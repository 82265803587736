<template>
    <div class="msg_preview_wpr">
        <div class="msg_block">
            <div class="msg_preview">
                <h2>{{ email.subject }}</h2>
                <div class="sender_info">
                    <img src="@/assets/images/default-avatar.svg" alt="">
                    <div class="info">
                        <h5>{{ user.businessname ? user.businessname : 'Thrive Coach'}}</h5>
                        <h6><span>To:</span>{{ contact.name }}</h6>
                    </div>
                </div>
                <div class="email-logo" v-if="email.show_email_logo">
                    <img :src="email.email_logo ? email.email_logo : require('@/assets/images/thumb/default-logo.svg')" />
                </div>
                <div class="redactor-styles redactor-in redactor-in-0 p-0 email-preview" v-html="replaceVariables(email.body)"></div>
                <div v-html="generateSignature(email.is_signature, email.signature_id)"></div>
            </div>
            <div class="msgFooter text-center mt-2">
                <p>
                    <template v-if="membership.has_white_label && whiteLabel.has_label == 1">
                        <a v-if="whiteLabel.has_link == 1" style="text-decoration: none; color:#525252;" :href="whiteLabel.link">⚡ Powered by {{ whiteLabel.powered_by }}</a>
                        <a v-else style="text-decoration: none; color:#525252;" href="https://superfitcoaching.com">⚡ Powered by {{ whiteLabel.powered_by }}</a>
                    </template>
                    <a v-else style="text-decoration: none;  color:#525252;" href="https://superfitcoaching.com">⚡ Powered by Thrive Coach</a>
                </p>
                <p style="text-decoration: none; color:#525252;">{{ user.businessname }}</p>
                <p><a style="color: #525252;" href="javascript:void(0);">Unsubscribe</a></p>
            </div>
        </div>
        <div class="history_preview ml-3">
            <h2>Email History</h2>
            <div class="modal_body p-0">
                <div class="result_wpr small m-0">
                    <!-- <div class="actions">
                        <ul>
                            <li class="optionDrops sort_list" @click="togglePerPageFilter"  v-click-outside="closePageFilter">
                                {{ params.per_page }}<i class="fas fa-angle-down"></i></li>
                                <page-filter v-model="params.per_page" :is-dropdown="true" :type="2" ref="email-preview-per-page-filter" />
                            <li>
                                <input type="text" class="p-0" placeholder="Search" @input="isTyping = true" v-model.trim="params.search" /><i class="fas fa-search"></i>
                            </li>
                            <li class="list_info">
                                {{ sendEmailEventLogs.from ? sendEmailEventLogs.from : 0 }} - {{ sendEmailEventLogs.to ? sendEmailEventLogs.to : 0 }} of <span>{{ sendEmailEventLogs.total ? sendEmailEventLogs.total : 0 }}</span>
                            </li>
                        </ul>
                    </div> -->
                    <div v-if="loader"><line-loader /></div>
                    <table class="standard">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Event</th>
                                <th>Timestamp</th>
                            </tr>
                        </thead>
                        <tbody  v-if="sendEmailEventLogs.total">
                            <tr v-for="(sendEmailEventLog, e) in sendEmailEventLogs.data" :key="e">
                                <td></td>
                                <td>
                                    <div class="user_wpr">
                                        <h4>{{ sendEmailEventLog.event }}</h4>
                                    </div>
                                </td>
                                <td>{{ sendEmailEventLog.event_date ? moment(sendEmailEventLog.event_date ).format('ll | LT') : ''}}</td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <td colspan="3" class="text_center">No Records Found</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="PaginationControl pull-right mt-3" v-show="sendEmailEventLogs.total">
                    <pagination v-model="params.page" :range-size="0" :pages="sendEmailEventLogs.last_page" @update:modelValue="handlePagination" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapActions, mapGetters } from 'vuex'

    import moment from 'moment'

    export default {
        Name: 'Notification Email Preview',

        data () {
            return {
                moment,
                params: {
                    page: 1,
                    per_page: 10,
                    message_id: '',
                },
            }
        },

        props: {
            email: Object,
            contact: Object,
        },

        emit: ['update:modelValue'],

        computed: {
            ...mapState({
                user: state => state.authModule.user,
                membership: state => state.authModule.membership,
                whiteLabel: state => state.authModule.whiteLabel,
                sendEmailEventLogs: state => state.contactModule.sendEmailEventLogs,
                loader: state => state.contactModule.sendEmailEventLogsLoader,
            }),

            ...mapGetters({
                replaceVariables: 'commonModule/REPLACE_VARIABLES',
                generateSignature: 'commonModule/GENERATE_SIGNATURE',
            }),
        },

        mounted () {
            const vm = this;

            vm.resetParams();
        },

        methods: {
            ...mapActions({
                getSendEmailEventLogs: 'contactModule/getSendEmailEventLogs',
            }),

            togglePerPageFilter () {
                const vm = this;
                const filter = vm.$refs['email-preview-per-page-filter'];

                if (filter) {
                    filter.dropdown = !filter.dropdown;
                }
            },

            closePageFilter () {
                const vm = this;
                const filter = vm.$refs['email-preview-per-page-filter'];

                if (filter) {
                    filter.dropdown = false;
                }
            },

            handlePagination (page) {
                const vm = this;

                vm.params.page = page;
                // vm.getEmailEvents(vm.params);
            },

            resetParams () {
                const vm = this;

                vm.params = {
                    page: 1,
                    per_page: 10,
                    message_id: vm.email.message_id,
                };

                vm.getSendEmailEventLogs(vm.params);
            }
        }
    }
</script>

<style scoped>
    .msg_preview_wpr{
        max-width: 800px;
        width: 100%;
        margin:  auto;
        display: flex;
        flex-direction: column;
        gap: 50px;
        align-items: center;
    }
    .msg_block{
        width: 100%;
    }
    .dashboard {
        background: #f5f5f5;
        padding: 30px;
        align-items: center;
        text-align: left;
    }

    .tab .dashboard {
        padding: 30px;
        background: #fafafb;
    }

    .cell .dashboard {
        padding: 15px;
        background: #fafafb;
    }

    .msg_preview {
        padding: 30px;
        background: #fff;
        border: 1px solid #eaeaea;
        border-radius: 10px;
        max-width: 800px;
        width: 100%;
        margin-bottom: 20px;
        position: relative;
    }

    .content_preview {
        padding: 30px;
        background: #fff;
        border: 1px solid #eaeaea;
        border-radius: 10px;
        max-width: 800px;
        width: 100%;
        margin: 20px 0;
        position: relative;
        border-radius: 10px;
    }

    .msg_preview:after {
        position: absolute;
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 262.83 148.44'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23fff;stroke:%23c7c6c6;stroke-miterlimit:10;stroke-width:7px;%7D%3C/style%3E%3C/defs%3E%3Cpolyline class='cls-1' points='2.58 2.36 131.41 143.25 260.24 2.36'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
        width: 20px;
        height: 13px;
        left: calc(50% - 10px);
        top: 100%;
    }

    .cell .msg_preview {
        padding: 20px;
    }

    .cell .content_preview {
        padding: 15px 20px;
    }

    .sender_info {
        display: flex;
        padding-bottom: 5px;
        margin-bottom: 15px;
    }

    .sender_info img {
        width: 35px;
        height: 35px;
        margin-right: 10px;
    }

    .sender_info .info {
        margin: 0;
    }

    .sender_info .info h5 {
        font-size: 13px;
        line-height: 15px;
        font-weight: 500;
        color: #2f7eed;
        margin-bottom: 3px;
    }

    .sender_info .info h6 {
        font-size: 11px;
        line-height: 13px;
        font-weight: 500;
        color: #5a5a5a;
        margin-bottom: 3px;
    }

    .sender_info .info h6 span {
        color: #121525;
        font-weight: 600;
        padding-right: 5px;
    }

    :deep(.desk .msg_preview p), :deep(.desk .content_preview p)  {
        font-size: 15px !important;
        line-height: 22px !important;
    }

    :deep(.tab .msg_preview p), :deep(.tab .content_preview p) {
        font-size: 14px !important;
        line-height: 20px !important;
    }

    :deep(.cell .msg_preview p), :deep(.cell .content_preview p) {
        font-size: 11px !important;
        line-height: 16px !important;
    }

    .desk .msg_preview h2 {
        font-size: 17px;
        line-height: 22px;
        margin-bottom: 25px;
        font-weight: 500;
        color: #121525;
    }

    .tab .msg_preview h2 {
        font-size: 15px;
        line-height: 20px;
        margin-bottom: 20px;
        font-weight: 500;
        color: #121525;
    }

    .cell .msg_preview h2 {
        font-size: 13px;
        line-height: 18px;
        margin-bottom: 15px;
        font-weight: 500;
        color: #121525;
    }

    .msgFooter p {
        font-size: 13px;
        line-height: 21px;
    }

    .tab .msgFooter p {
        font-size: 12px;
        line-height: 19px;
    }

    .cell .msgFooter p {
        font-size: 11px;
        line-height: 17px;
    }

    .msgFooter p a {
        text-decoration: none;
    }

    .preview_area .area_header .logo_wpr img {
        width: 40px
    }

    .msgFooter {
        max-width: 800px !important;
    }

    .tab .msgFooter {
        max-width: 800px;
    }

    .cell .msgFooter {
        max-width: 800px;
    }

    .history_preview {
        padding: 30px;
        background: #fff;
        border: 1px solid #eaeaea;
        border-radius: 10px;
        max-width: 800px;
        width: 100%;
        margin-bottom: 20px;
        position: relative;
    }
    .cell .history_preview{
        padding: 20px;
    }

    .desk .history_preview h2 {
        font-size: 17px;
        line-height: 22px;
        margin-bottom: 25px;
        font-weight: 500;
        color: #121525;
    }

    .tab .history_preview h2 {
        font-size: 15px;
        line-height: 20px;
        margin-bottom: 20px;
        font-weight: 500;
        color: #121525;
    }

    .cell .history_preview h2 {
        font-size: 13px;
        line-height: 18px;
        margin-bottom: 15px;
        font-weight: 500;
        color: #121525;
    }

    .result_wpr table.standard td:first-child, .result_wpr table.standard th:first-child{
        width: auto;
        text-align: left;
    }
    .result_wpr.small table.standard td.action{
        width: 30px;
    }
    .result_wpr.small table.standard td.action i{
        width: auto;
        font-size: 13px;
    }
    .result_wpr.small table.standard td .drp_wrapper, .tempcard ul li .drp_wrapper{
        display: none;
    }
    .result_wpr.small table.standard td .drp_wrapper.active, .tempcard ul li .drp_wrapper.active{
        display: block;
    }
    .result_wpr table.standard td.text_center {
        width: auto;
        text-align: center !important;
    }

    /* --------- for table inside mail body for mobile view --------- */

    .cell .msg_preview :deep(.email-preview table){
        width: 300px !important;
        margin: 0 !important;
    }
    .cell .msg_preview :deep(.email-preview table td){
        padding: 0 !important;
    }
    .cell .msg_preview :deep(.email-preview table td *){
        font-size: 11px !important;
    }
    
    /* ---------------- end ----------------- */
</style>
